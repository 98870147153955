import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phoneNumber: '',
  tempToken: '',
  otp: '',
  isAuthenticated: false,
  token: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setTempToken: (state, action) => {
      state.tempToken = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    loginSuccess: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.phoneNumber = '';
      state.otp = '';
      state.token = '';
      state.tempToken = '';
    },
  },
});

export const { setPhoneNumber, setOtp, setTempToken, setToken, loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;
