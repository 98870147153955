import React, { useState } from "react";
import { Form, Input, Button, Typography, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setPhoneNumber, setOtp, setTempToken, loginSuccess, setToken } from "./redux/slices/authSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const API_URL = process.env.REACT_APP_API_URL;

const LoginPage = () => {
  const dispatch = useDispatch();
  const { phoneNumber, tempToken, token } = useSelector((state) => state.auth);
  const [phoneSubmitted, setPhoneSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onPhoneSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/users/login`, {
        phone_number: values.phone,
      });
  
      dispatch(setPhoneNumber(values.phone));
      dispatch(setTempToken(response.data.token));
      setPhoneSubmitted(true);
    } catch (error) {
      if (error.response) {
        // Server responded with a non-2xx status
        alert(error.response.data.message || "An error occurred");
      } else {
        console.error("Error submitting phone number: ", error);
        alert("An unexpected error occurred. Please try again.");
      }
    }
    setLoading(false);
  };
  

  const onOTPSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/users/login/verify`,
        { code: values.otp, phone_number: "123" },
        { headers: { Authorization: `Bearer ${tempToken}` } }
      );
      if (response.status === 200) {
        try {
          if (response.data.data.register_user) {
            alert("Account not found");
            return;
          }
        } catch(e) {
          console.log(e);
        }
        dispatch(setOtp(values.otp));
        dispatch(loginSuccess());
        dispatch(setToken(response.data.data.token))
        localStorage.setItem("authToken", response.data.data.token);
        alert("Login successful!");
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("OTP verification failed: ", error);
    }
    setLoading(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", background: "#f0f2f5" }}>
      <Card style={{ width: 350, padding: 20, textAlign: "center" }}>
        <img src="https://www.kyleb.app/images/werzat_logo.png" width="200px" />
        <Title level={2}>{phoneSubmitted ? "Enter OTP" : "Login"}</Title>
        <Form name="login" onFinish={phoneSubmitted ? onOTPSubmit : onPhoneSubmit} layout="vertical">
          {!phoneSubmitted ? (
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[{ required: true, message: "Please input your phone number!" }]}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>
          ) : (
            <Form.Item
              name="otp"
              label="OTP"
              rules={[{ required: true, message: "Please input the OTP!" }]}
            >
              <Input placeholder="Enter OTP" />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              {phoneSubmitted ? "Verify OTP" : "Next"}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;