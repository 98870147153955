import React, { useEffect, useState } from "react";
import { Card, Typography, Descriptions, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const { Title } = Typography;
const API_URL = process.env.REACT_APP_API_URL;

const OrderDetailPage = () => {
  const { id } = useParams();
  const token = localStorage.getItem("authToken");
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/admin/orders/get/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 401) {
        navigate("/"); // Redirect to login on unauthorized
        return;
      }

      if (response.data.success) {
        setOrderDetails(response.data.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/"); // Redirect on 401 error
      } else {
        console.error("Error fetching order details", error);
      }
    }
    setLoading(false);
  };

  if (loading || !orderDetails) return <Spin size="large" style={{ display: "block", margin: "50px auto" }} />;

  const { order, user } = orderDetails;

  return (
    <div style={{ padding: "20px" }}>
      <Card>
        <Title level={3}>Order Details</Title>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Order ID">{order.id}</Descriptions.Item>
          <Descriptions.Item label="MealMe ID">{order.mealme_id}</Descriptions.Item>
          <Descriptions.Item label="Stripe Intent ID">{order.intent_id || "N/A"}</Descriptions.Item>
          <Descriptions.Item label="Order Date">{order.order_date}</Descriptions.Item>
          <Descriptions.Item label="Status">{order.status}</Descriptions.Item>
          <Descriptions.Item label="MealMe Status">{order.mealme_status}</Descriptions.Item>
          <Descriptions.Item label="Total">${order.total / 100}</Descriptions.Item>
        </Descriptions>
      </Card>

      <Card style={{ marginTop: "20px" }}>
        <Title level={3}>User Information</Title>
        {user ? (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
            <Descriptions.Item label="Phone">{user.phone}</Descriptions.Item>
            <Descriptions.Item label="First Name">{user.first_name}</Descriptions.Item>
            <Descriptions.Item label="Last Name">{user.last_name}</Descriptions.Item>
            <Descriptions.Item label="Provider">{user.provider}</Descriptions.Item>
            <Descriptions.Item label="Stripe ID">{user.stripe_id || "N/A"}</Descriptions.Item>
          </Descriptions>
        ) : (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="User">DELETED USER</Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    </div>
  );
};

export default OrderDetailPage;