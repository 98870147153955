import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice'; // Import reducer

const store = configureStore({
  reducer: {
    auth: authReducer, // Register the auth slice
  },
});

export default store;
