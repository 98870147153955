import React, { useEffect, useState } from "react";
import { Table, Input, Select, Button, Card, Typography } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./redux/slices/authSlice";

const { Title } = Typography;
const { Option } = Select;
const API_URL = process.env.REACT_APP_API_URL;

const DashboardPage = () => {
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [searchMethod, setSearchMethod] = useState("mealme_id");
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);

    const onDeleteSubmit = async (values) => {
        setLoading(true);
        try {
          const response = await axios.delete(
            `${API_URL}/users/delete`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.status === 200) {
            dispatch(setToken(null));
            localStorage.setItem("authToken", "");
            alert("Delete account successful!");
            navigate("/");
          }
        } catch (error) {
          console.error("OTP verification failed: ", error);
        }
        setLoading(false);
      };

    return (
        <div style={{ padding: "20px" }}>
            <Card style={{maxWidth: '280px', margin: '0 auto', textAlign: 'center'}}>
                <img src="https://www.kyleb.app/images/werzat_logo.png" width="200px" />
                <Title level={2}>Delete Account</Title>
                Are you sure you want to delete your account from the Werzat app?
                <Button onClick={onDeleteSubmit} style={{marginTop: '25px', color: 'red'}}>Yes, Delete My Account</Button>
            </Card>
        </div>
    );
};

export default DashboardPage;
